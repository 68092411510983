<template>
    <div style="min-width: 800px">
        <todos :todos="row.item.todos" :callback="refreshTable"></todos>
    </div>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
}
</script>